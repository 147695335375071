body {
    overflow-x: hidden;
}

#kontakt {
    // background-image: url(../img/Sprechblase.svg);
    background: $white;
    position: fixed;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 80;
    padding: 30px 0 0 25px;
    filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2));
    @include breakpoint(medium) {
        right: 50px;
        bottom: 50px;
    }
/*    @include breakpoint(large) {
        right: calc(100vw / 9);
        bottom: 240px;
    }*/
    @include breakpoint(xxlarge) {
        right: calc(100vw / 8);
        bottom: 40px;
    }
}

#start {
    position: relative;
    &:before {
        display: block;
        content: "";
        background-image: url(../img/bearbeitet_Cut.jpg);
        background-size: 110%;
        width: 100%;
        height: 200px;
        @include breakpoint(medium) {
            background-size: cover;
            background-position: 50% 70%;
            height: 890px;
        }
        @include breakpoint(large) {
            background-position: 70% 30%;
            //background-size: 110%;
            height: 690px;
            //width: calc(100% - 80px);
            //margin:0 40px;
        }
        @include breakpoint(xxlarge) {
            height: 970px;
            //background-size: cover;
            width: calc(100% - 140px);
            margin: 0 70px;
        }
    }
    .image {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 200px;
        @include breakpoint(medium) {
            height: 890px;
        }
        @include breakpoint(large) {
            height: 690px;
        }
        @include breakpoint(xxlarge) {
            height: 970px;
        }
    }
    .textslider {
        display: block;
        position: relative;
        width: 560px;
        height: 370px;
        margin-top: 250px;
        margin-bottom: 75px;
        @include breakpoint(medium) {
            margin-top: 250px;
            margin-bottom: 15px;
            transform: scale(0.725);
            margin-left: -70px;
        }
        @include breakpoint(large) {
            margin-top: 50px;
            margin-bottom: 20px;
            transform: scale(1);
            margin-left: 0px;
        }
        @include breakpoint(xxlarge) {
            margin-top: 200px;
            margin-bottom: 75px;
        }
        &:before {
            display: block;
            content: "";
            background-image: url(../img/Rahmen.svg);
            height: 12px;
        }
        &:after {
            display: block;
            position: absolute;
            content: "";
            background-image: url(../img/Rahmen.svg);
            transform: rotate(180deg);
            bottom: 0;
            width: 560px;
            height: 12px;
        }
        > div {
            position: absolute;
            top: 10px;
            left: 30px;
            right: 30px;
            bottom: 10px;
            display: flex;
            align-items: center;
            //justify-content: center;
        }
        p {
            font-size: 55px;
            font-style: italic;
            font-family: $header-font-family;
            span {
                font-size: 5rem;
                line-height: 5.313rem;
                font-style: normal;
            }
        }
    }
    .circlular_buttons {
        position: relative;
        @include breakpoint(medium) {
            margin-left: -20px;
            width: 120%;
        }
        @include breakpoint(large) {
            margin-left: 0;
            width: 100%;
        }
        a {
            border-radius: 50%;
            display: block;
            color: $white;
            float: left;
            font-size: 1.5rem;
            text-align: center;
            span {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.marken {
                background: $marken;
                height: 170px;
                width: 170px;
                margin-top: 0.5rem;
                line-height: 1.3;
                font-family: $header-font-family;
                font-style: italic;
                @include breakpoint(medium) {
                    //animation: markenfade 8s linear 1s infinite;
                    &:hover {
                        background: saturate($marken, 30%);
                    }
                }
            }
            &.infrastruktur {
                background: $infrastruktur;
                height: 210px;
                width: 210px;
                margin-top: 2rem;
                font-family: $header-font-family;
                font-size: 1.313rem;
                @include breakpoint(medium) {
                    height: 180px;
                    width: 180px;
                    //animation: infrastrukturfade 6s linear 3s infinite;
                    &:hover {
                        background: saturate($infrastruktur, 30%);
                    }
                }
                @include breakpoint(large) {
                    height: 210px;
                    width: 210px;
                }
            }
            &.neues {
                background: $neues;
                height: 205px;
                width: 205px;
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                @include breakpoint(medium) {
                    height: 180px;
                    width: 180px;
                    //animation: neuesfade 4s linear 2s infinite;
                    &:hover {
                        background: desaturate($neues, 20%);
                    }
                }
                @include breakpoint(large) {
                    height: 205px;
                    width: 205px;
                }
            }
            &.profil {
                background: $profil;
                height: 165px;
                width: 165px;
                margin-top: 2.25rem;
                font-family: $header-font-family;
                font-style: italic;
                @include breakpoint(medium) {
                    //animation: profilfade 7s linear 4s infinite;
                    &:hover {
                        background: saturate($profil, 30%);
                    }
                }
            }
            /* Animation Keyframes*/
            /*            
            @keyframes markenfade {
                0% {
                    background: $marken;
                }
                50% {
                    background: desaturate($marken, 10%)
                }
                100% {
                    background: $marken;
                }
            }
            
            @keyframes infrastrukturfade {
                0% {
                    background: $infrastruktur;
                }
                50% {
                    background: lighten($infrastruktur, 10%)
                }
                100% {
                    background: $infrastruktur;
                }
            }
            
            @keyframes neuesfade {
                0% {
                    background: $neues;
                }
                50% {
                    background: desaturate($neues, 20%)
                }
                100% {
                    background: $neues;
                }
            }
            
            @keyframes profilfade {
                0% {
                    background: $profil;
                }
                50% {
                    background: darken($profil, 10%)
                }
                100% {
                    background: $profil;
                }
            }
   
*/
            /* hover*/
        }
    }
}

#content {
    ul.mobile-accordion {
        margin-bottom: 0;
        .grid-container {
            position: relative;
        }
        li.accordion-item {
            &.marken {
                background: $marken;
            }
            &.infrastruktur {
                background: $infrastruktur;
            }
            &.neues {
                background: $neues;
            }
            &.profil {
                background: $profil;
            }
            @include breakpoint(large) {
                height: 1050px;
                background: transparent !important;
            }
            a.accordion-title {
                font-family: "Noto Serif", serif;
                font-style: italic;
                font-size: 1.5rem;
                text-decoration: none !important;
                padding: 1.5rem 1rem 1.25rem 1rem;
                color: white;
                height: 75px;
                -webkit-transition: all .5s ease;
                transition: all .5s ease;
                &.accordion-title:hover,
                &.accordion-title:focus {
                    background: inherit;
                }
            }
            .accordion-title::before {
                position: absolute;
                opacity: 1;
                top: 50%;
                right: 1rem;
                margin-top: -0.5rem;
                content: '▼';
                -webkit-transition: all .1s ease;
                transition: all .1s ease;
            }
            &.is-active .accordion-title {
                height: 0px;
                padding: 0;
                -webkit-transition: all .5s ease;
                transition: all .5s ease;
                opacity: 0;
                pointer-events: none !important;
                &::before {
                    -webkit-transition: all .1s ease;
                    transition: all .1s ease;
                    opacity: 0;
                }
            }
            .accordion-content {
                padding: 1rem;
                border: 0;
            }
        }
        @include breakpoint(medium) {
            .accordion-title {
                display: none;
            }
            .accordion-content {
                display: block !important;
                padding: 0 !important;
            }
        }
    }
    .circle_container {
        position: relative;
        @include breakpoint(medium) {
            .circle {
                padding: 0 1rem 1rem 1rem;
            }
        }
        @include breakpoint(large) {
            .circle {
                height: 925px;
                width: 925px;
                border-radius: 50%;
                border: solid black 20px;
                margin-top: 4rem;
                z-index: 50;
                background: $white;
                padding: 5rem 9rem;
            }
        }
        &:before {
            display: block;
            content: "";
            background-size: 110%;
            width: 100%;
            height: 115px;
            margin-top: 80px;
            @include breakpoint(medium) {
                background-size: 100%;
                width: 100%;
                height: 275px;
                margin-top: 0px;
                mask: url(../img/Maske_Image_rund.svg) bottom center / cover;
                transition: filter 0.5s ease;
                margin-bottom: 3.5rem;
            }
            @include breakpoint(large) {
                display: none;
                height: 1050px;
                width: 1100px;
                border-radius: 0;
                background-size: cover;
                position: absolute;
                left: 0;
                mask: none;
            }
            @include breakpoint(xlarge) {
                height: 1050px;
                width: 1200px;
            }
            @include breakpoint(xxlarge) {
                height: 1050px;
                width: 1380px;
            }
        }
        h1 {
            font-size: 3rem;
            line-height: 3rem;
            text-align: center;
            margin-top: -190px;
            margin-bottom: 170px;
            @include breakpoint(medium) {
                font-size: 4.688rem;
                margin-top: 0;
                margin-bottom: 2.5rem;
                line-height: 5.25rem;
            }
        }
        p {
            font-size: 1.125rem;
        }
        strong {
            font-size: 1.188rem;
            font-weight: 600;
        }
        ul {
            padding: 1rem 0;
            li {
                font-size: 1.125rem;
                font-weight: 600;
                padding: 0 1.75rem 0 1rem;
                line-height: 2rem;
            }
        }
        .button {
            color: $white;
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.625rem;
            margin: 2rem 0;
            &:hover {
                color: darken($white, 10%);
            }
        }
    }
    .marken {
        @include breakpoint(small only) {
            .circle_container::before {
                margin-top: 130px;
            }
            .circle_container h1 {
                margin-top: -240px;
            }
        }
        h1,
        li {
            color: $marken;
        }
        h1 {
            font-style: italic;
        }
        .circle_container::before {
            background-image: url(../img/josef-schmaus-2018-dialog_test.jpg);
        }
        .circle_container .circle {
            border-color: $marken;
            @include breakpoint(large) {
                padding: 9rem 8rem;
            }
        }
        .button {
            background: $marken;
        }
        a:not(.button) {
            text-decoration: underline;
            color: $marken;
            &:hover {
                color: saturate($marken, 50%);
            }
        }
    }
    .infrastruktur {
        @include breakpoint(small only) {
            .circle_container::before {
                margin-top: 130px;
            }
            .circle_container h1 {
                margin-top: -240px;
                margin-left: -20px;
            }
        }
        h1,
        li {
            color: $infrastruktur;
        }
        .circle_container::before {
            background-image: url(../img/sandburg-2757106_1920_sw.jpg);
            @include breakpoint(large) {
                right: 0;
                left: auto;
            }
        }
        .circle_container .circle {
            border-color: $infrastruktur;
            @include breakpoint(large) {
                padding: 6rem 9rem;
            }
        }
        .button {
            background: $infrastruktur;
        }
    }
    .neues {
        @include breakpoint(small only) {
            .circle_container::before {
                margin-top: 130px;
            }
            .circle_container h1 {
                margin-top: -240px;
            }
        }
        h1,
        li {
            color: $neues;
        }
        h1 {
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
        }
        .circle_container::before {
            background-image: url(../img/beachvolleybal_-02.jpg);
        }
        .circle_container .circle {
            border-color: $neues;
            @include breakpoint(large) {
                padding: 10rem 9rem;
            }
        }
        .button {
            background: $neues;
        }
    }
    .profil {
        @include breakpoint(small only) {
            .circle_container::before {
                margin-top: 90px;
            }
            .circle_container h1 {
                margin-top: -195px;
            }
        }
        @include breakpoint(large) {
            height: 800px !important;
            .circle_container::before {
                display: block !important;
            }
        }
        @include breakpoint(xxlarge) {
            height: 970px !important;
        }
        h1,
        li {
            color: $profil;
        }
        h1 {
            font-style: italic;
        }
        .circle_container::before {
            background-image: url(../img/josef-schmaus-2018-dialog_blaustichig.jpg);
            background-position: center center;
            @include breakpoint(large) {
                background-position: 30% 30%;
                background-size: cover;
                width: 100%;
                height: 800px;
            }
            @include breakpoint(xxlarge) {
                height: 970px;
                width: calc(100% - 140px);
                margin: 0 70px;
                //background-size: 110%;
            }
        }
        .circle_container .circle {
            border-color: $profil;
            @include breakpoint(large) {
                height: 620px;
                width: 620px;
                padding: 5rem 6rem;
                margin-top: 6rem;
            }
            @include breakpoint(xxlarge) {
                height: 620px;
                margin-top: 10rem;
            }
        }
        .button {
            background: $profil;
        }
    }
}

.circle_container {
    &:before {
        transition: filter 4s;
    }
}

.imprint {
    a {
        color: $marken;
    }
    margin:4rem 1rem;
    @include breakpoint(medium) {
        margin: 4rem 0;
        h3 {
            margin: 3rem 0 1rem 0;
        }
    }
}

//blur effekt
@include breakpoint(medium) {
    .circle_container .parallax-background.blured {
        filter: blur(3px) opacity(.8);
    }
}

//parallax
.parallax-background {
    // background-image: url(../img/josef-schmaus-2018-dialog_test.jpg);
    display: block;
    background-size: 110%;
    width: 100%;
    height: 115px;
    margin-top: 80px;
    @include breakpoint(medium) {
        background-size: 100%;
        width: 100%;
        height: 275px;
        margin-top: 0px;
        mask: url(../img/Maske_Image_rund.svg) bottom center / cover;
        transition: filter 0.5s ease;
    }
    @include breakpoint(large) {
        height: 1050px;
        width: 1100px;
        border-radius: 0;
        background-size: cover;
        position: absolute;
        left: 0;
        mask: none;
    }
    @include breakpoint(xlarge) {
        height: 1050px;
        width: 1200px;
    }
    @include breakpoint(xxlarge) {
        height: 1050px;
        width: 1380px;
    }
}

.accordion-item {
    overflow: hidden;
}

.parallax-background {
    display: none;
}

@include breakpoint(large) {
    .parallax-background {
        display: block;
    }
    .marken {
        .parallax-background {
            background-image: url(../img/josef-schmaus-2018-dialog_test.jpg);
            top: -270px;
            @include breakpoint(xxlarge) {
                top: -380px;
            }
        }
    }
    .infrastruktur {
        .parallax-background {
            background-image: url(../img/sandburg-2757106_1920_sw.jpg);
            right: 0;
            left: auto;
            top: -600px;
            @include breakpoint(xxlarge) {
                top: -700px;
            }
        }
    }
    .neues {
        .parallax-background {
            background-image: url(../img/beachvolleybal_-02.jpg);
            top: -980px;
            @include breakpoint(xxlarge) {
                top: -1050px;
            }
        }
    }
}