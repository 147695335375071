#footer {
    background: $neues;
    @include breakpoint(medium) {
        background: transparent;
        
    }
    @include breakpoint(large) {
        margin-top:-190px;
    }
    
    p{
        background: $white;
        margin:0;
        padding: 1.5rem 1rem;
        font-family: $header-font-family;
        font-style: italic;
        font-size: 0.8125rem;
        text-align: center;
    }
    .circle_menu {
        @include breakpoint(medium) {
        
            margin-bottom:50px;
       
        }
        @include breakpoint(large) {
       justify-content: left;
        margin-top:-50px;
            margin-bottom:50px;
        }
        @include breakpoint(xlarge) {
      
        margin-top:-120px;
            margin-bottom:120px;
        }
        

        .circle {
            
            text-align: center;
            color: $white;
            font-weight: 300;
            padding:1.25rem 0;
            line-height: 2.5rem;

            
            a {
                display: inline;
                font-size: 1.313rem;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                color: $white;
            }
            &.web a{
                    display: block;
                    
                }
        }
        @include breakpoint(medium) {
            .circle {
                background: $neues;
                width: 190px;
                height: 190px;
                border-radius: 50%;
                font-size: 0.9375rem;
           
                text-align: center;
                display: flex;
                justify-content: space-around; //space-evenly not supported by edge
                align-items: center;
                span {
                    display: flex;
                    flex-direction: column;
                }
                & > span{
                    width:100%;
                }
                a {
                    padding: 0;
                    line-height: 2rem;
                }
                &.dprg > span{
                    margin-top:-40px;
                    
                }
                &.web > span{
                    margin-top:-10px;
                    
                }
                
                
            }
        }
    }
    .menu.bottombar {
        background: $black;
        a {
            color: $white;
        }
    }
}