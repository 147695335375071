/*$app-dashboard-top-nav-height: rem-calc(55);
.app-dashboard-top-nav-bar {
    height: $app-dashboard-top-nav-height;
    width: 100%;
    flex: 0 0 $app-dashboard-top-nav-height;
}*/

.sticky-container {
    height: 53px;
    z-index: 100;
    @include breakpoint(medium) {
        height: auto;
    }
}

.title-bar {
    transition: all .25s ease;
    height: 52px;
    @include breakpoint(medium) {
        height: 134px;
    }
    @include breakpoint(large) {
        height: auto;
    }
    
    .grid-container {
        position: relative;
        padding-right: 0;
        padding-left: 0;
        @include breakpoint(medium) {
            padding-right: 0.625rem;
            padding-left: 0.625rem;
        }
    }
    .Logo {
        color: #373737;
        font-family: $header-font-family;
        font-style: italic;
        position: relative;
        .Logo__capital {
            font-size: 4.375rem;
            display: block;
            position: absolute;
            top: -0.75rem;
            left: 0.25rem;
            transition: all .25s ease;
            
            @include breakpoint(medium) {
                font-size: 9.375rem;
                top: -3.938rem;
                left: 2.25rem;
            }
            
            @include breakpoint(large) {
                font-size: 8.125rem;
                top: -1.188rem;
                left: -2.125rem;
            }
        }
        .Logo__text {
            margin-top: 0rem;
            display: block;
            margin-left: 4.3125rem;
            transition: all .25s ease;
            @include breakpoint(medium) {
                margin-top: -1.75rem;
                margin-left: 12.5625rem;
            }
            @include breakpoint(large) {
                margin-top: 0.625rem;
                margin-left: 6rem;
            }
            .Logo__name {
//FONT FRAGE
                font-family: 'Roboto', sans-serif;
                font-size: 1.625rem;
                text-transform: uppercase;
                font-weight: 300;
                font-style: normal;
                letter-spacing: 0.05em;
                white-space: nowrap;
                @include breakpoint(medium) {
                    font-size: 2.625rem;
                }
            }
            .Logo__untertitel {
                display: none;
                @include breakpoint(medium) {
                    display: block;
                    font-size:0.875rem;
                    margin-top: -0.3125rem;
                }
            }
        }
    }
    .menu {
        margin-top: 0rem;
        @include breakpoint(medium) {
            margin-top: -0.625rem;
        }
        @include breakpoint(large) {
            margin-top: 0.9375rem;
        }
        a {
            color: #373737;
            font-family: $header-font-family;
            font-style: italic;
            font-size: 0.9375rem;
            padding: 0.7rem 0.7rem;
            &.round {
                
                
                border: solid 1px #373737;
                border-radius: 50%;
                width: 38px;
                height: 38px;
                padding: 9px;
                float: right;
                @include breakpoint(medium) {
                    margin-right: 0;
                }
                @include breakpoint(large) {
                    margin-right: 1rem;
                }
                @include breakpoint(xlarge) {
                    margin-right: 0;
                }
            }
        }
    }
    &.shrink {
        .Logo {
            .Logo__text {
                margin-top: -0.5rem;
            }
            .Logo__capital {
                
                top: -2.25rem;
               
            }
        }
    }
}